<template>
  <div>
    <!-- 头部 -->
    <div class="search">
      <el-row>
        <el-col :span="4">
          <div class="search_input">
            <el-input v-model="condition" placeholder="请输入查找昵称"></el-input>
          </div>
        </el-col>
        <el-col :span="7">
          <div class="search_input">
            <el-button class="btn" type="primary" @click="reset">重置</el-button>
            <el-button class="btn" type="primary" @click="getSearch">搜索</el-button>
            <el-button class="btn" type="primary" @click="exportHandleClick">导出列表</el-button>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 表格 -->
    <MoTable :cols="cols" :tableData="tableData" @handleEventButton="handleEventButton" :operation="operation"
      :page="page" />
    <!-- 详情 -->
    <el-dialog width="30%" title="详情" :visible.sync="dialogVisible" append-to-body>
      <el-form label-width="80px" :model="Form">
        <el-form-item label="昵称">
          <el-input v-model="Form.name" disabled></el-input>
        </el-form-item>
        <el-form-item label="线索内容">
          <el-input v-model="Form.content" type="textarea" disabled rows="5" resize="none"></el-input>
        </el-form-item>

        <el-form-item label="电话号码">
          <el-input v-model="Form.phoneNum" disabled></el-input>
        </el-form-item>
        <el-form-item label="地址">
          <el-input v-model="Form.address" disabled></el-input>
        </el-form-item>
        <el-form-item label="提交时间">
          <el-input v-model="Form.createTime" disabled></el-input>
        </el-form-item>
        <el-form-item label="图片">
          <el-image v-for="(item, index) in Form.urlsBean" :key="index" style="width: 100px; height: 100px" :src="item"
            :preview-src-list="Form.urlsBean">
          </el-image>
        </el-form-item>
        <el-form-item label="积分奖励" prop="integral">
          <el-input v-model.number="Form.integral" disabled></el-input>
        </el-form-item>
        <el-form-item label="审核原因" prop="reason">
          <el-input disabled v-model="Form.reason" type="textarea" rows="5" resize="none"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑 -->
    <el-dialog width="30%" title="编辑" :visible.sync="dialogVisibleEdit" append-to-body :before-close="onClose">
      <el-form label-width="80px" ref="FormEdit" :model="FormEdit" :rules="rulesFormEdit">
        <el-form-item label="积分奖励" :prop="FormEdit.checkStatus == 0 ? '' : 'integral'">
          <el-input v-model.number="FormEdit.integral" :disabled="FormEdit.checkStatus == 0 ? true : false"></el-input>
        </el-form-item>
        <el-form-item label="审核状态" prop="checkStatus">
          <el-select v-model="FormEdit.checkStatus" placeholder="请选择审核状态">
            <el-option v-for="item in optionsType" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="审核原因" prop="reason">
          <el-input v-model.trim="FormEdit.reason" type="textarea" rows="5" resize="none"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onClose">取 消</el-button>
        <el-button type="primary" @click="onSubmit('FormEdit')">确定</el-button>
      </span>
    </el-dialog>
    <!-- 下载 -->
    <el-dialog title="导出公益列表" :visible.sync="isExport" width="30%" :before-close="handleCloseExport">
      <el-form ref="form" :model="formExport" label-width="80px" style="text-align: left;">
        <el-form-item label="审核状态">
          <el-select v-model="formExport.checkStatus" placeholder="选择审核状态">
            <el-option label="驳回" :value="0"></el-option>
            <el-option label="通过" :value="1"></el-option>
            <el-option label="待审核" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="日期">
          <el-date-picker v-model="time" type="daterange" value-format="yyyy-MM-dd" range-separator="至"
            start-placeholder="开始月份" end-placeholder="结束月份">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCloseExport">取 消</el-button>
        <el-button type="primary" @click="exportHandle">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import MoTable from "../../components/MoTable/MoTable";
export default {
  components: {
    MoTable,
  },
  data() {
    return {
      condition: "",
      cols: [
        { type: "index", label: "序号" },
        { label: "昵称", prop: "name" },
        { label: "地址", prop: "address" },
        { label: "提交时间", prop: "createTime" },
        { label: "电话号码", prop: "phoneNum" },
        { label: "积分", prop: "integral" },
        {
          label: "审核状态",
          prop: "checkStatus",
          formatter: (row, column, cellValue, index) => {
            if (row.checkStatus == 2) {
              return "未审核";
            } else if (row.checkStatus == 1) {
              return "已采纳";
            } else {
              return "驳回";
            }
          },
        },
      ],
      tableData: [],
      operation: {
        details: true,
        edit: true,
        del: true,
      },
      page: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      Form: {},
      dialogVisible: false,
      dialogVisibleEdit: false,
      FormEdit: {
        id: "",
        integral: undefined,
        openId: "",
        reason: "",
        checkStatus: "",
      },
      optionsType: [
        { id: 0, name: "已驳回" },
        { id: 1, name: "已通过" },
      ],
      name: "FormEdit",
      isExport: false,
      time: null,
      formExport: {
        startTime: null,
        endTime: null,
        checkStatus: null
      }
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    async getList() {
      const res = await this.$get("/welfare/getList", {
        ...this.page,
        condition: this.condition,
      });
      // console.log(res);
      if (res.flag) {
        this.tableData = res.data.rows;
        this.page.total = res.data.total;
      }
    },
    getSearch() {
      this.page.pageNum = 1;
      this.page.pageSize = 10;
      this.getList();
    },
    reset() {
      this.page.pageNum = 1;
      this.page.pageSize = 10;
      this.condition = "";
      this.getList();
    },
    handleEventButton(type, row) {
      console.log(row);
      if (type == "details") {
        this.dialogVisible = true;
        this.Form = row;
      } else if (type == "del") {
        this.$confirm("确认删除？")
          .then((_) => {
            this.handleDel(row);
            done();
          })
          .catch((_) => { });
      } else if (type == "edit") {
        this.FormEdit.id = row.id;
        this.FormEdit.openId = row.openId;
        this.dialogVisibleEdit = true;
      } else if (type == "current") {
        this.page.pageNum = row;
        this.getList();
      }
    },
    async handleDel(row) {
      const res = await this.$del("/welfare/delById/" + row.id);
      console.log(res);
      if (res.flag) {
        this.$message.success(res.message);
        this.getList();
      } else {
        this.$message.error(res.message);
      }
    },
    onClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.$refs[this.name].resetFields();
          this.dialogVisibleEdit = false;
          done();
        })
        .catch((_) => { });
    },
    onSubmit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const res = await this.$put("/welfare/change", this.FormEdit);
          console.log(res);
          if (res.flag) {
            this.$message.success(res.message);
            this.$refs[formName].resetFields();
            this.dialogVisibleEdit = false;
          } else {
            this.$message.success("服务器繁忙");
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    exportHandleClick() {
      this.isExport = true
    },
    // 导出列表
    async exportHandle() {
      if (this.time) {
        this.formExport.startTime = this.time[0]
        this.formExport.endTime = this.time[1]
      }
      let res = await this.$exl(`/welfare/exportWelfareList`, { ...this.formExport });
      console.log(res);
      const blob = new Blob([res], {
        type: "application/vnd.ms-excel;",
      });
      const fileName = "公益.xlsx";
      const elink = document.createElement("a");
      elink.download = fileName;
      elink.style.display = "none";
      elink.href = URL.createObjectURL(blob);
      document.body.appendChild(elink);
      elink.click();
      URL.revokeObjectURL(elink.href); // 释放URL 对象
      document.body.removeChild(elink);
      this.isExport = false
      this.formExport = {
        startTime: null,
        endTime: null,
        checkStatus: null
      }
    },
    handleCloseExport() {
      this.isExport = false
    }
  },
  computed: {
    rulesFormEdit() {
      return {
        reason: [{ required: true, message: "请输入原因", trigger: "blur" }],
        integral: [
          { required: true, message: "请输入积分", trigger: "blur" },
          { type: "number", message: "请输入数字", trigger: "blur" },
        ],
        checkStatus: [
          { required: true, message: "请选择类型", trigger: "change" },
        ],
      };
    },
  },
};
</script>

<style lang="less" scoped>
.search {
  width: 100%;
  height: 80px;
  background: #ffffff;
  border-radius: 16px;
  margin-top: 16px;

  .search_input {
    height: 80px;
    display: flex;
    align-items: center;
    padding: 0 10px 0 30px;

    .btn {
      width: 110px;
      height: 40px;
    }
  }
}
</style>